<template>
  <ul class="app-header-nav">
    <li style="width: 100px;"
      :class="{ active: item.menuId && $route.params.id === item.menuId }"
      v-for="item in list"
      :key="item.menuId"
      @mousemove="show(item)"
      @mouseleave="hide(item)"
    >
      <RouterLink @click="hide(item)" :to="`${item.path}`">{{
        item.name
      }}</RouterLink>
      <div class="layer" :class="{ open: item.open }" v-if="item.children">
        <ul class="list">
          <!--子菜单-->
          <li class="sub" v-for="sub in item.children" :key="sub.menuId">
            <RouterLink @click="hide(item)" :to="`/solution#section5`">
              <span class="title">{{ sub.name }}</span>
              <!-- v-for="son in result.item" :key="son.menuId" -->
              <!-- {{item.children}} -->
               <!-- <span>{{item}}</span> -->
              <!-- <img :src="sub.imagePath" alt=""> -->
              <!-- <ul class="sub-ul">
                <li>{{solutionList.item}}</li>
              </ul> -->
            </RouterLink>  
          </li>
          <ul class="list-son" v-for="item in result" :key="item.menuId" style="color: aliceblue;">
            <!-- {{ item.title }} -->
            <RouterLink to="/">
              <li class="son_title" style="type='disc'"  v-for="(i,j) in item.son" :key="j" >{{ i }}</li>
            </RouterLink>  
          </ul>
        </ul>
      </div>
    </li>
    <li class="contact">
      <router-link to="/link/contact">
        <div class="contact-img">
          <img src="@\assets\images\bnt_bg_ask.png" alt="立即咨询" />
          <span class="contact-text">立即咨询</span>
        </div>
      </router-link>
    </li>
  </ul>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "AppHeaderNav",
  setup() {
    const store = useStore();
    // 拿到vuex中的分类列表
    const list = computed(() => {
      return store.state.menu.list;
      // return store.state.category.list
    });
    // 跳转的时候不会关闭二级类目，通过数据来控制
    // 1. vuex每个分类加上open数据
    // 2. vuex提供显示和隐藏函数，修改open数据
    // 3. 组件中使用vuex中的函数，使用时间来绑定，提供一个类名显示隐藏的类名
    const show = (item) => {
      store.commit("menu/show", item.menuId);
    };
    const hide = (item) => {
      store.commit("menu/hide", item.menuId);
    };
    const { target, result } = {
      target: "操作成功",
      result: [
        {
          menuId: "1",
          title: "传感器",
          son:[ "智慧农业解决方案","智能烟感解决方案","温度传感器","湿度传感器"],
        },
        {
          menuId: "2",
          title: "数据采集网关",
          son:[ "工业边缘计算网关","室外LoRaWAN网关","AI工业网关","现场总线网关"],
        },
        {
          menuId: "3",
          title: "工控电脑",
          son:[ "过程控制解决方案","工业机器人","人机界面","车间自动化","数据采集监控"],
        },
        {
          menuId: "4",
          title: "智慧交通",
          son:[ "车载终端","车辆违停识别","车牌识别","闯红灯识别"],
        },
        {
          menuId: "5",
          title: "物联网教具",
          son:[ "教学实训","智慧教室","智能录播","数字化校园"],
        },
      ],
    };
    return { list, show, hide,result }; //,solutionList:target,result
  },
  created() {
    console.log(this.list);
    console.log("---------------------");
    console.log(this.list[0].children);
    console.log(this.result);
  },
};
</script>
<style scoped lang="less">

.app-header-nav {
  //大ul样式
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 0px;
  padding: 0 0px;
  position: relative;
  z-index: 999;
  > li {
    display: block;
    margin-right: 0px;
    flex: 1;
    width: 100px;
    text-align: center;
    padding: 0px;
    > a {
      font-size: 16px;
      line-height: 32px;
      height: 32px;
      font-weight: bold;
      display: inline-block;
      text-align: center;
      white-space: nowrap;
    }
    &:hover,
    &.active {
      > a {
        color: @baseColor;
        // border-bottom: 1px solid #fff;
      }
      显示二级类目 > .layer {
        height: 132px;
        opacity: 1;
      }
    }
  }
}
// 二级类名弹层
.layer {
  box-shadow: 1px 1px 30px rgb(0, 0, 0 / 6%);
  display: flex;
  background-size: cover; //保持图像的纵横比并将图像缩放成将适合背景定位区域的最大大小。
  height: 800px;
  &.open {
    height: 232px; //修改子菜单高度
    opacity: 0.85; //设置元素透明度，0完全透明，1不透明
  }
  //width: 1240px;
  background-color: rgb(51, 51, 51);
  position: absolute;
  left: -200px;
  top: 56px;
  height: 0;
  overflow: hidden;
  opacity: 1;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.2s 0.1s;
  .list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 50px;
    // align-items: center;
    height: 132px;
    // vertical-align: top;
    li {
      // margin:20px;
      width: 200px;
      text-align: left;
      padding: 5px;
      vertical-align: top;
      img {
        width: 60px;
        height: 60px;
      }
      span {
        padding-top: 10px;
        color: #fff;
        font-size: 18px;
      }
      &:hover {//修改下拉框鼠标悬浮效果
        span {
          // color:red;
          text-decoration: underline;
        }
        ul{
          text-decoration: underline;
          color: white;
        }
      }
    }
  }
}
.contact {
  flex: 1;
  width: 70px;
  height: 34px;
  position: relative;
  // border-bottom: 1px solid #e7e7e7;
  line-height: 32px;
  margin-left: auto;
  text-align: center;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .contact-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 142px;
        height: 29px;
      }
    }
    .contact-text {
      position: absolute;
      top: 3;
      left: 50%;
      transform: translate(-50%);
      // background-color: rgba(255, 255, 255, 0.7);
      padding: 8pax 16px;
      font-size: 16px;
      text-align: center;
      color: #fff;
    }
  }
  .contact {
    margin-top: 5px;
    font-size: 14px;
    color: #666;
  }
}
.list-son{
  list-style-type:disc;
  // display: table;
  li{
    height: auto;
    vertical-align: top;
    font-size: 16px;
    color:#bec3c6;
  }
  li:hover {
    //修改下拉框鼠标悬浮效果
    // color:red;
    text-decoration: underline;
    // color: white;
      
  }

}
</style>
